<template>
  <b-card-code :title="$route.name === 'disposisi-selesai' ? 'Tabel Disposisi Selesai' : 'Tabel Proses Disposisi'">
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Cari</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Masukkan kata kunci"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="dataRows"
      :is-loading="loading"
      style-class="vgt-table striped"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        position: 'bottom'
      }"
    >
      <div
        slot="emptystate"
        align="center"
      >
        No Data
      </div>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'NoDisposisi'"
          class="text-nowrap"
        >
          <span
            v-b-tooltip.hover.right="'Klik untuk lihat Detail'"
            class="text-nowrap link-no"
            variant="outline-primary"
            @click="detailDisposisi(props.row.id)"
          >{{ props.row.NoDisposisi }}</span>
        </span>

        <!-- Column: Status -->
        <span
          v-else-if="props.column.field === 'Status'"
          v-b-modal.modal-center
          :title="props.row.Komentar"
        >
          <b-badge :variant="statusVariant(props.row.Status)">
            {{ props.row.Status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'Aksi'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none nopad"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="editDisposisi(props.row.id)">
                <feather-icon
                  :icon="authorize ? 'Edit2Icon' : 'EyeIcon' "
                  class="mr-50"
                />
                <span>{{ authorize ? 'Edit' : 'Lihat' }} </span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              {{ fromPage }} sampai {{ toPage }}
            </span>
            <span class="text-nowrap">, dari {{ totalPage }} entri </span>
            <b-form-select
              v-model="pageLength"
              :options="['5','10','20','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />

          </div>

          <div>
            <template>
              <b-button
                variant="outline-primary"
                class="bg-gradient-primary mt-2"
                pill
                :disabled="!prev"
                size="sm"
                @click="prev ? getDisposisi('prev') : ''"
              >
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </b-button>
            </template>
            <template>
              <b-button
                variant="outline-primary"
                class="bg-gradient-primary mt-2 ml-1"
                pill
                :disabled="!next"
                size="sm"
                @click="next ? getDisposisi('next') : ''"
              >
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </b-button>
            </template>
          </div>
        </div>
      </template>
    </vue-good-table>

  </b-card-code>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BLink,
  VBTooltip,
  VBModal,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import axios from '@axios'
import dayjs from 'dayjs'
import store from '@/store/index'

export default {
  components: {
    BLink,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    VBModal,
    VBTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  data() {
    return {
      pageTitle: '',
      loading: true,
      pageLength: 10,
      dir: false,
      totalPage: 0,
      totalRow: 0,
      page: 0,
      fromPage: 0,
      toPage: 0,
      // codeBasic,
      columns: [
        {
          label: 'No. Disposisi',
          field: 'NoDisposisi',
        },
        {
          label: 'Perihal',
          field: 'Perihal',
        },
        {
          label: 'Waktu',
          field: 'Waktu',
        },
        {
          label: 'Deadline',
          field: 'Deadline',
        },
        {
          label: 'Pengirim',
          field: 'Pengirim',
        },
        {
          label: 'Status',
          field: 'Status',
        },
        {
          label: 'Aksi',
          field: 'Aksi',
        },
      ],
      dataRows: [
        {
          id: '',
          NoDisposisi: '',
          Perihal: '',
          Waktu: '',
          authorize: false,
          Deadline: '',
          Pengirim: '',
          Status: '',
          Aksi: '',
          prev: '',
          next: '',
          Komentar: [],
        },
      ],
      searchTerm: '',
      Status: [
        {
          1: 'Procces',
          2: 'Proses',
          3: 'Rejected',
          4: 'Resigned',
          5: 'Applied',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Procces: 'light-primary',
        Proses: 'light-success',
        Failed: 'light-danger',
        Resigned: 'light-warning',
        Process: 'light-info',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
  },
  watch: {
    $route() {
      this.getDisposisi()
    },
    pageLength() {
      this.getDisposisi()
    },
  },
  created() {
    if (
      store.state.userData.permission.find(e => e === 'SIAP.Disposition.Code.Z')
    ) {
      this.authorize = true
    } else {
      this.authorize = false
    }
  },
  mounted() {
    this.getDisposisi()
  },
  methods: {
    editDisposisi(e) {
      if (this.authorize) {
        window.location.href = `edit-disposisi/${e}`
      } else {
        window.location.href = `detail-disposisi/${e}`
      }
    },
    detailDisposisi(e) {
      window.location.href = `detail-disposisi/${e}`
    },
    async getDisposisi(val) {
      const defaultUrl = '/siap/dispo'
      let url = ''
      if (val === 'next') {
        url = this.next_url
      } else if (val === 'prev') {
        url = this.prev_url
      } else {
        url = defaultUrl
      }
      const { data } = await axios.get(url, {
        params: {
          status:
            this.$route.name === 'disposisi-selesai' ? 'success' : 'proccess',
          // page: this.page === 0 ? null : this.page,
          limit: this.pageLength,
        },
      })
      this.totalPage = data.total
      this.totalRow = data.per_page
      this.prev = data.prev_url
      this.next = data.next_url
      this.fromPage = data.from
      this.toPage = data.to
      if (data.total !== 0) {
        this.dataRows = data.data.map(e => ({
          id: e.id,
          NoDisposisi: e.disposition !== null ? e.code : 'data kosong',
          Perihal: e.disposition !== null ? e.title : 'data kosong',
          Waktu:
            e.disposition !== null
              ? dayjs(e.created_at).format('DD-MM-YYYY')
              : 'data kosong',
          Deadline:
            e.disposition !== null
              ? dayjs(e.deadline.date).format('DD-MM-YYYY')
              : 'data kosong',
          Pengirim: e.disposition !== null ? e.from : 'data kosong',
          Status: e.disposition !== null ? e.status.name : 'data kosong',
          // Komentar: e.disposition !== null ? e.responders.map(y => ({ id: y.id, nama: y.role_name, komentar: y.comment })) : 'data kosong',
          Aksi: '',
        }))
      } else {
        this.dataRows = []
      }
      this.loading = false
      //   .catch(error => {
      //     console.log(error)
      //   })
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
.nopad {
  padding: 0;
}
.link-no {
  border-bottom: solid 1px #c5c5c5;
  padding-bottom: 3px;
  cursor: pointer;
}
</style>
